import { withDependencies } from '@wix/thunderbolt-ioc'
import { ISiteScrollBlocker } from './ISiteScrollBlocker'
import { BrowserWindow, BrowserWindowSymbol } from '@wix/thunderbolt-symbols'
import { isSSR } from '@wix/thunderbolt-commons'
import fastdom from 'fastdom'

const siteScrollBlockerFactory = (window: BrowserWindow): ISiteScrollBlocker => {
	let _scrollCorrection = 0

	const restoreScrollPosition = () => {
		window!.scrollTo(0, _scrollCorrection)
	}

	const blockSiteScrolling = (bodyElement: HTMLBodyElement, siteContainer: HTMLElement) => {
		fastdom.measure(() => {
			_scrollCorrection = window!.scrollY

			siteContainer.style.marginTop = `${-Math.max(0.5, _scrollCorrection)}px`
			bodyElement.classList.add('blockSiteScrolling')
		})
	}

	const unblockSiteScrolling = (bodyElement: HTMLBodyElement, siteContainer: HTMLElement) => {
		bodyElement.classList.remove('blockSiteScrolling')
		siteContainer.style.marginTop = ''

		restoreScrollPosition()
	}

	return {
		setSiteScrollingBlocked: (blocked: boolean) => {
			if (isSSR(window)) {
				return
			}
			const bodyElement = window!.document.getElementsByTagName('body').item(0) as HTMLBodyElement
			const siteContainer = window!.document.getElementById('site-container') as HTMLElement

			if (blocked) {
				blockSiteScrolling(bodyElement, siteContainer)
			} else {
				unblockSiteScrolling(bodyElement, siteContainer)
			}
		},
	}
}

export const SiteScrollBlocker = withDependencies([BrowserWindowSymbol], siteScrollBlockerFactory)
